/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';

	const service_saiyo = () => {
		if($('.service_saiyo-toggle').length) {
			const getJSONarticle = (_code) => {
				return $.getJSON(`/ajax/service_saiyo/article/${_code}.json`);
			};
			let baseURL = '';
			if(document.domain === 'review.cmsod.jp') {
				baseURL = 'https://staging.api.saponet.mynavi.jp';
			}
			$.ajax({
				method: 'get',
				url: baseURL + '/mypage/my_info/'
			}).then(function (response) {
				// console.log('service-login');
				for(let i=0; i < $('.service_saiyo-toggle').length; i++) {
					let code = $('.service_saiyo-toggle').eq(i).find('.rt_cf_nt_code').text();
					getJSONarticle(code).done((data) => {
						// console.log(data.rt_bn_article_info);
						if(data.rt_bn_article_info.length) {
							for(let l=0; l < data.rt_bn_article_info.length; l++) {
								let link = data.rt_bn_article_info[l].rt_cf_n_href_article.href;
								let title = data.rt_bn_article_info[l].rt_cf_n_title.content[0];
								let subtitle = data.rt_bn_article_info[l].rt_cf_n_sub_title;
								let img = data.rt_bn_article_info[l].rt_cf_n_thum_img;
								let download,downliadBlock;
								// console.log(data.rt_bn_article_info[l].rt_cf_n_sub_title);
								if(!link) {
									link = '';
								}
								if(!title) {
									title = '';
								}
								if(!subtitle) {
									subtitle = '';
								} else {
									subtitle = data.rt_bn_article_info[l].rt_cf_n_sub_title.content[0];
								}
								if(!img) {
									img = '';
								} else {
									img = data.rt_bn_article_info[l].rt_cf_n_thum_img.src;
								}
								if(!(data.rt_bn_article_info[l].rt_cf_n_download_url)) {
									download = '';
									downliadBlock = download;
								} else {
									if(!response.login) {
										download = '/account/login/';
										downliadBlock = '<a href="'+download+'" target="_blank" class="mod-button _border mt20">'+
										'<span>資料ダウンロードする<svg class="icon"><use xlink:href="/common/svg/sprite.svg#download" /></svg></span>'+
										'</a>';
									} else {
										download = data.rt_bn_article_info[l].rt_cf_n_download_url.content[2];
										let filename = data.rt_bn_article_info[l].rt_cf_n_download_url.content[0];
										downliadBlock = '<a href="'+download+'" target="_blank" class="mod-button _border mt20 js-service-download" data-filename="'+filename+'">'+
										'<span>資料ダウンロードする<svg class="icon"><use xlink:href="/common/svg/sprite.svg#download" /></svg></span>'+
										'</a>';
									}
								}
								let HTML = '<div class="__item"><ul class="pure-g space-u-20"><li class="pure-u-5-24 pure-u-md-1-1 space-u-20-child">'+
									'<figure class="mod-border mod-borderRadius">'+
									'<img src="'+img+'" class="width-u-100" alt="'+title+'" />'+
									'</figure></li>'+
									'<li class="pure-u-12-24 pure-u-md-1-1 space-u-20-child">'+
									'<h3 class="fs_18 fs_md_16">'+title+'</h3>'+
									'<p>'+subtitle+'</p></li>'+
									'<li class="pure-u-7-24 pure-u-md-1-1 space-u-20-child">'+
									'<a href="'+link+'" target="_blank" class="mod-button _blue">'+
									'<span>もっとみる<svg class="icon"><use xlink:href="/common/svg/sprite.svg#blank" /></svg></span>'+
									'</a>'+
									downliadBlock+
									'</li></ul></div>';
								$('.service_saiyo-toggle').eq(i).find('.js-toggleBlock').append(HTML);
							}
							$('.service_saiyo-toggle').eq(i).find('.js-toggleBlock').append('<div class="mt30"><a href="/contact/saiyo/" class="mod-button _border _400"><span>お問い合わせ<svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_right"></use></svg></span></a></div>');
						}
					});
				}
			})
			$(document).on('click', '.js-service-download', function(){
				let thisurl = $(this).attr('href');
				let thisfilename = $(this).data('filename');
				// console.log($(this).attr('href'));
				// console.log($(this).data('filename'));
				$.ajax({ url: baseURL + '/contact/files/download/?name='+thisfilename+'&url='+thisurl });
			});
		}
	};
	const service_saiyo_attention = () => {
		if($('.js-service_saiyo_attention').length) {
			let baseURL = '';
			if(document.domain === 'review.cmsod.jp') {
				baseURL = 'https://staging.api.saponet.mynavi.jp';
			}
			$.ajax({
				method: 'get',
				url: baseURL + '/mypage/my_info/'
			}).then(function (response) {
				// console.log(response.login,'response.login');
				for(let i=0; i <$('.js-service_saiyo_attention').length; i++) {
					$('.js-service_saiyo_attention').eq(i).find('a').attr('target','_blank');
				}
				if(!response.login) {
					for(let l=0; l <$('.js-service_saiyo_attention').length; l++) {
						if($('.js-service_saiyo_attention').eq(l).find('.rt_cf_n_remarks').length) {
							$('.js-service_saiyo_attention').eq(l).find('a').attr('href','/account/login/');
							$('.js-service_saiyo_attention').eq(l).find('a').removeClass('di_none');
						}
					}
				} else {
					for(let l=0; l <$('.js-service_saiyo_attention').length; l++) {
						if($('.js-service_saiyo_attention').eq(l).find('.rt_cf_n_remarks').length) {
							$('.js-service_saiyo_attention').eq(l).find('a').attr('href',$('.js-service_saiyo_attention').eq(l).find('.rt_cf_n_remarks').html().split('<br>')[1]);
							$('.js-service_saiyo_attention').eq(l).find('a').removeClass('di_none');
						}
					}
					$('.js-service_saiyo_attention a').on('click', function(){
						let linkurl = $(this).attr('href');
						let linktext = $(this).parents('.js-service_saiyo_attention').find('.rt_cf_n_remarks').html().split('<br>')[0];
						// console.log(linkurl,linktext);
						$.ajax({ url: baseURL + '/contact/files/download/?name='+linktext+'&url='+linkurl });
					});
				}
			})
		}
	};
	const service_creer_detail = () => {
		if($('.js-service_career-detail-relation').length) {
			const getJSONrelation = (_tags) => {
				return $.getJSON(`/ajax/service_career/detail/related.json${_tags}`);
			};
			let detailid =$('.rt_cf_n_key').text();
			let tags ='';
			for(let i=0; i < $('.rt_cf_n_tag_code_service_career').length; i++) {
				if(!(i == 0)) {
					tags += '&tag='+$('.rt_cf_n_tag_code_service_career').eq(i).text();
				} else {
					tags += '?tag='+$('.rt_cf_n_tag_code_service_career').eq(i).text();
				}
			}
			// console.log(tags);
			getJSONrelation(tags).done((data) => {
				// console.log(data.rt_bn_related_article_info,"tagsList");
				let HTML = '';
				for(let i=0; i < data.rt_bn_related_article_info.length; i++) {
					let link = data.rt_bn_related_article_info[i].rt_cf_n_href_service_career.href;
					let title = data.rt_bn_related_article_info[i].rt_cf_n_title.content[0];
					if(!link) {
						link = 'null'
					}
					if(!title) {
						title = 'null'
					}
					if(!(link === location.pathname)) {
						HTML += '<p class="di_iblock"><a href="'+link+'" class="mod-link01">'+title+'</a><span> ／ </span></p>';
					} else {
						HTML += '<p class="di_iblock">'+title+'<span> ／ </span></p>';
					}
				}
				$('.js-service_career-detail-relation').append(HTML);
			});
		}
	};
	service_saiyo();
	service_saiyo_attention();
	service_creer_detail();

})(window.jQuery, window.FUNCTIONS);